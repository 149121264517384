import { NounDeclension } from '~/src/classes.ts';

interface DeclensionPattern {
    declension?: NounDeclension;
    extendedDeclension?: NounDeclension;
    info?: string;
    warning?: string;
}

const declensionPatterns: Record<string, DeclensionPattern> = {
    'Y-Formen': {
        info: `
            Bekannt als {https://www.bpb.de/shop/zeitschriften/apuz/geschlechtergerechte-sprache-2022/346085/entgendern-nach-phettberg/=Entgendern nach Phettberg}.<br><br>
            Im Singular wird als Artikel das Neutrum verwendet.
        `,
        declension: new NounDeclension({
            N: 'das Arbeity',
            G: 'des Arbeitys',
            D: 'dem Arbeity',
            A: 'das Arbeity',
            N_pl: 'die Arbeitys',
            G_pl: 'der Arbeitys',
            D_pl: 'den Arbeitys',
            A_pl: 'die Arbeitys',
        }),
        extendedDeclension: new NounDeclension({
            N: 'mein bestes Lehry',
            G: 'meines besten Lehrys',
            D: 'meinem besten Lehry',
            A: 'mein bestes Lehry',
            N_pl: 'meine besten Lehrys',
            G_pl: 'meiner besten Lehrys',
            D_pl: 'meinen besten Lehrys',
            A_pl: 'meine besten Lehrys',
        }),
    },
    'I-Formen': {
        info: `
            Verwendet u.a. in den Romanen „{https://projektwerkstatt.de/media/text/ebooks_hinter_den_laboren.pdf=Hinter den Laboren}“
            und „{https://projektwerkstatt.de/media/text/ebooks_suizidalien.pdf=Suizidalien}“,
            sowie bekannt in aktivistischen Kontexten.<br><br>
            Eine Variante benutzt {https://uebers-klima-reden.de/aktivisti/=das -i Suffix als geschlechtsneutralen Pluralmarker},
            medial ist jedoch {https://uebers-klima-reden.de/aus-aktivisti-werden-aktivistis/=das -i Suffix als Singularmarker und -is als Pluralmarker} etabliert.
            <br><br>
            Im Singular wird als Artikel das Neutrum verwendet.
        `,
        declension: new NounDeclension({
            N: 'das Arbeiti',
            G: 'des Arbeitis',
            D: 'dem Arbeiti',
            A: 'das Arbeiti',
            N_pl: 'die Arbeitis',
            G_pl: 'der Arbeitis',
            D_pl: 'den Arbeitis',
            A_pl: 'die Arbeitis',
        }),
        extendedDeclension: new NounDeclension({
            N: 'mein bestes Lehri',
            G: 'meines besten Lehris',
            D: 'meinem besten Lehri',
            A: 'mein bestes Lehri',
            N_pl: 'meine besten Lehris',
            G_pl: 'meiner besten Lehris',
            D_pl: 'meinen besten Lehris',
            A_pl: 'meine besten Lehris',
        }),
    },
    'Inklusivum': {
        info: `
            Formen vorgestellt vom {https://geschlechtsneutral.net/=Verein für geschlechtsneutrales Deutsch}.<br><br>
            Siehe auch: {/en/em=Neopronomen „en“}.
        `,
        declension: new NounDeclension({
            N: 'de Arbeitere',
            G: 'ders Arbeiteres',
            D: 'derm Arbeitere',
            A: 'de Arbeitere',
            N_pl: 'die Arbeiterne',
            G_pl: 'der Arbeiterne',
            D_pl: 'den Arbeiternen',
            A_pl: 'die Arbeiterne',
        }),
        extendedDeclension: new NounDeclension({
            N: 'mein beste Lehrere',
            G: 'meiners besten Lehreres',
            D: 'meinerm besten Lehrere',
            A: 'mein beste Lehrere',
            N_pl: 'meine besten Lehrerne',
            G_pl: 'meiner besten Lehrerne',
            D_pl: 'meinen besten Lehrernen',
            A_pl: 'meine besten Lehrerne',
        }),
    },
    'Indefinitivum': {
        info: `
            Formen vorgestellt von {https://www.geschlechtsneutral.com/lit/Liminalis-2008-Sylvain-Balzer.pdf=Cabala de Sylvain und Carsten Balzer}.<br><br>
            Siehe auch: {/nin=Neopronomen „nin/nim“}.
        `,
        declension: new NounDeclension({
            N: 'din Arbeiternin',
            G: 'dins Lehrernins',
            D: 'dim Lehrernin',
            A: 'din Lehrernin',
            N_pl: 'die Arbeiterninnen',
            G_pl: 'der Arbeiterninnen',
            D_pl: 'den Arbeiterninnen',
            A_pl: 'die Arbeiterninnen',
        }),
        extendedDeclension: new NounDeclension({
            N: 'meinin beste Lehrernin',
            G: 'meinins beste Lehrernins',
            D: 'meinim beste Lehrernin',
            A: 'meinin beste Lehrernin',
            N_pl: 'meine besten Lehrerninnen',
            G_pl: 'meiner besten Lehrerninnen',
            D_pl: 'meinen besten Lehrerninnen',
            A_pl: 'meine besten Lehrerninnen',
        }),
    },
    'ens-Formen': {
        info: `
            Formen vorgestellt von Lann Hornscheidt.<br><br>
            Siehe auch: {/ens=Neopronomen „ens“}.<br><br>
            „ens“ ist dabei dem Mittelteil aus „Mensch“ entnommen.
        `,
        declension: new NounDeclension({
            N: 'dens Arbeitens',
            G: 'dens Arbeitens',
            D: 'dens Arbeitens',
            A: 'dens Arbeitens',
            N_pl: 'die Arbeitense',
            G_pl: 'der Arbeitense',
            D_pl: 'den Arbeitense',
            A_pl: 'die Arbeitense',
        }),
        extendedDeclension: new NounDeclension({
            N: 'meinens beste Lehrense',
            G: 'meinens beste Lehrense',
            D: 'meinens beste Lehrense',
            A: 'meinens beste Lehrense',
            N_pl: 'meine besten Lehrense',
            G_pl: 'meiner besten Lehrense',
            D_pl: 'meinen besten Lehrense',
            A_pl: 'meine besten Lehrense',
        }),
    },
    'ex-Formen': {
        info: `
            Formen vorgestellt von {https://www.lannhornscheidt.com/w_ortungen/nonbinare-w_ortungen/=Lann Hornscheidt und Lio Oppenländer}.<br><br>
            Siehe auch: {/ex=Neopronomen „ex“}.<br><br>
            „ex“ steht für Exit Gender und soll in Abgrenzung zu anderen Formen
            explizit beschreiben, dass sich eine Person nicht über Gender versteht.
        `,
        declension: new NounDeclension({
            N: 'Arbeitex',
            G: 'Arbeitex',
            D: 'Arbeitex',
            A: 'Arbeitex',
        }),
    },
    'Ojum': {
        info: `
            Formen vorgestellt von {https://www.frumble.de/blog/2021/03/26/ueberlegungen-zu-einer-genderneutralen-deutschen-grammatik=Frumble}.<br><br>
            Siehe auch: {/oj=Neopronomen „oj/ojm“}.<br><br>
            Die Idee ist, die theoretisch unspezifischen Formen auf -t, -ent, -ant und -or
            im Singular nicht durchzugendern:
            Wird das Ojum bei -er statt des generischen Maskulinums der Standard,
            verändert sich mittelfristig voraussichtlich die Spracherwartung und ein inklusiver Artikel davor reicht,
            um diese Formen als genderneutral zu markieren und die gewohnten Kurzformen erhalten zu können.
            Trotzdem ist kurzfristig auch immer explizite Singular-Genderung mit -u freigestellt.
        `,
        declension: new NounDeclension({
            N: 'dej Arbeitu',
            G: 'dejs Arbeitus',
            D: 'dojm Arbeitu',
            A: 'dojn Arbeitu',
            N_pl: 'die Arbeitoj',
            G_pl: 'der Arbeitoj',
            D_pl: 'den Arbeitojn',
            A_pl: 'die Arbeitoj',
        }),
        extendedDeclension: new NounDeclension({
            N: 'meinu bestu Lehru',
            G: 'meinus besten Lehrus',
            D: 'meinum besten Lehru',
            A: 'meinun bestu Lehru',
            N_pl: 'meine besten Lehroj',
            G_pl: 'meiner besten Lehroj',
            D_pl: 'meinen besten Lehrojn',
            A_pl: 'meine besten Lehrojn',
        }),
    },
    'NoNa-System': {
        info: 'Formen vorgestellt von {https://geschlechtsneutralesdeutsch.com/=Geschlechtsneutrales Deutsch}.',
        declension: new NounDeclension({
            N: 'dai Arbeiter*in',
            G: 'dais Arbeiter*in',
            D: 'dam Arbeiter*in',
            A: 'dai Arbeiter*in',
            N_pl: 'die Arbeiter*innen',
            G_pl: 'der Arbeiter*innen',
            D_pl: 'den Arbeiter*innen',
            A_pl: 'die Arbeiter*innen',
        }),
        extendedDeclension: new NounDeclension({
            N: 'meint beste Lehrer*in',
            G: 'meinter besten Lehrer*in',
            D: 'meintem besten Lehrer*in',
            A: 'meint beste Lehrer*in',
            N_pl: 'meine besten Lehrer*innen',
            G_pl: 'meiner besten Lehrer*innen',
            D_pl: 'meinen besten Lehrer*innen',
            A_pl: 'meine besten Lehrer*innen',
        }),
    },
    'Person-Formen': {
        declension: new NounDeclension({
            N: 'die Arbeitsperson',
            G: 'der Arbeitsperson',
            D: 'der Arbeitsperson',
            A: 'die Arbeitsperson',
            N_pl: 'die Arbeitspersonen',
            G_pl: 'der Arbeitspersonen',
            D_pl: 'den Arbeitspersonen',
            A_pl: 'die Arbeitspersonen',
        }),
        extendedDeclension: new NounDeclension({
            N: 'meine beste Lehrperson',
            G: 'meiner besten Lehrperson',
            D: 'meiner besten Lehrperson',
            A: 'meine beste Lehrperson',
            N_pl: 'meine besten Lehrpersonen',
            G_pl: 'meiner besten Lehrpersonen',
            D_pl: 'meinen besten Lehrpersonen',
            A_pl: 'meine besten Lehrpersonen',
        }),
    },
    'Doppelpunkt-Formen': {
        declension: new NounDeclension({
            N: 'der:die Arbeiter:in',
            G: 'des:der Arbeiters:in',
            D: 'dem:der Arbeiter:in',
            A: 'den:die Arbeiter:in',
            N_pl: 'die Arbeiter:innen',
            G_pl: 'der Arbeiter:innen',
            D_pl: 'den Arbeiter:innen',
            A_pl: 'die Arbeiter:innen',
        }),
        extendedDeclension: new NounDeclension({
            N: 'mein:e beste:r Lehrer:in',
            G: 'meines:r besten Lehrer:in',
            D: 'meinem:r besten Lehrer:in',
            A: 'meine:n beste:n Lehrer:in',
            N_pl: 'meine besten Lehrer:innen',
            G_pl: 'meiner besten Lehrer:innen',
            D_pl: 'meinen besten Lehrer:innen',
            A_pl: 'meine besten Lehrer:innen',
        }),
    },
    'Unterstrich-Formen': {
        declension: new NounDeclension({
            N: 'der_die Arbeiter_in',
            G: 'des_der Arbeiters_in',
            D: 'dem_der Arbeiter_in',
            A: 'den_die Arbeiter_in',
            N_pl: 'die Arbeiter_innen',
            G_pl: 'der Arbeiter_innen',
            D_pl: 'den Arbeiter_innen',
            A_pl: 'die Arbeiter_innen',
        }),
        extendedDeclension: new NounDeclension({
            N: 'mein_e beste_r Lehrer_in',
            G: 'meines_r besten Lehrer_in',
            D: 'meinem_r besten Lehrer_in',
            A: 'meine_n beste_n Lehrer_in',
            N_pl: 'meine besten Lehrer_innen',
            G_pl: 'meiner besten Lehrer_innen',
            D_pl: 'meinen besten Lehrer_innen',
            A_pl: 'meine besten Lehrer_innen',
        }),
    },
    'Sternchen-Formen': {
        declension: new NounDeclension({
            N: 'der*die Arbeiter*in',
            G: 'des*der Arbeiters*in',
            D: 'dem*der Arbeiter*in',
            A: 'den*die Arbeiter*in',
            N_pl: 'die Arbeiter*innen',
            G_pl: 'der Arbeiter*innen',
            D_pl: 'den Arbeiter*innen',
            A_pl: 'die Arbeiter*innen',
        }),
        extendedDeclension: new NounDeclension({
            N: 'mein*e beste*r Lehrer*in',
            G: 'meines*r besten Lehrer*in',
            D: 'meinem*r besten Lehrer*in',
            A: 'meine*n beste*n Lehrer*in',
            N_pl: 'meine besten Lehrer*innen',
            G_pl: 'meiner besten Lehrer*innen',
            D_pl: 'meinen besten Lehrer*innen',
            A_pl: 'meine besten Lehrer*innen',
        }),
    },
    'Großbuchstabe-Formen': {
        warning: `
            Formen wie z.B. „ArbeiterIn“ oder „LehrerInnen“.
            Das Binnen-I bezieht sich nur auf die männliche und die weibliche Form der Wörter
            und schließt damit (wie das generische Maskulinium) immer noch sehr viele Menschen aus der Sprache aus.
            Deshalb ist diese Variante genauso wenig zu empfehlen wie das generische Maskulinum oder Femininum.
        `,
        declension: new NounDeclension({
            N: 'derDie ArbeiterIn',
            G: 'desDer ArbeiterIn',
            D: 'demDer ArbeiterIn',
            A: 'denDie ArbeiterIn',
            N_pl: 'die ArbeiterInnen',
            G_pl: 'der ArbeiterInnen',
            D_pl: 'den ArbeiterInnen',
            A_pl: 'die ArbeiterInnen',
        }),
        extendedDeclension: new NounDeclension({
            N: 'meinE besteR LehrerIn',
            G: 'meinesR besten LehrerIn',
            D: 'meinemR besten LehrerIn',
            A: 'meineN besteN LehrerIn',
            N_pl: 'meine besten LehrerInnen',
            G_pl: 'meiner besten LehrerInnen',
            D_pl: 'meinen besten LehrerInnen',
            A_pl: 'meine besten LehrerInnen',
        }),
    },
    'Diminuitiv': {
        warning: `
            Die Verniedlichungsform benutzt zwar das Neutrum und ist damit eine normativ neutrale Form,
            jedoch zeichnet diese Form üblicherweise kleine und junge Nomen aus.
            Daher solltest du vorsichtig sein und diese Substantive nur gebrauchen,
            wenn die entsprechende Person es sich ausdrücklich wünscht.
        `,
        declension: new NounDeclension({
            N: 'das Arbeiterchen',
            G: 'des Arbeiterchens',
            D: 'dem Arbeiterchen',
            A: 'das Arbeiterchen',
            N_pl: 'die Arbeiterchen',
            G_pl: 'der Arbeiterchen',
            D_pl: 'den Arbeiterchen',
            A_pl: 'die Arbeiterchen',
        }),
        extendedDeclension: new NounDeclension({
            N: 'mein bestes Lehrerchen',
            G: 'meines besten Lehrerchens',
            D: 'meinem besten Lehrerchen',
            A: 'mein bestes Lehrerchen',
            N_pl: 'meine besten Lehrerchen',
            G_pl: 'meiner besten Lehrerchen',
            D_pl: 'meinen besten Lehrerchen',
            A_pl: 'meine besten Lehrerchen',
        }),
    },
};

export default declensionPatterns;
